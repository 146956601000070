import React from 'react'
import Lottie from 'react-lottie'
import { Slide } from 'react-awesome-reveal'

import Laptop from '../../images/home/laptop.svg'

import * as desktopAnimation from '../../lottie/monitor.json'
const opts = {
  autoplay: true,
  loop: true,
  animationData: desktopAnimation.default,
}

export default function InnovationSection() {
  return (
    <section className="h-full w-full p-4 my-10 md:p-8 md:my-12 lg:my-16 xl:my-20 2xl:my-24 3xl:my-32 text-copy-primary">
      <div className="container mx-auto flex flex-col lg:flex-row">
        <div className="w-full text-center lg:w-2/5">
          <Slide triggerOnce cascase direction="left">
            <h2 className="text-3xl lg:text-4xl font-semibold lg:text-left xl:text-5xl">
              Innovation at Clarity
            </h2>
            <p className="leading-loose mt-6 text-lg md:text-xl text-center lg:text-left lg:leading-relaxed lg:pr-2">
              We understand that people are the wellspring of innovation. Their
              history and experience create a diversity of thought that inspires
              creativity. We resource and empower them with the autonomy they
              need to build solutions for mission success.
            </p>
            <Laptop style={{ marginTop: '2rem' }} className="home-laptop" />
          </Slide>
        </div>
        <div className="hidden lg:block w-full mt-16 lg:mt-0 lg:w-3/5">
          <Slide
            triggerOnce
            direction="right"
            style={{ alignSelf: 'flex-end' }}
          >
            <Lottie options={opts} />
          </Slide>
        </div>
      </div>
    </section>
  )
}
